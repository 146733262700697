import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import LoadingTablesCreateEditForm from './Components/LoadingTablesCreateEditForm';
import LoadingTablesApi from './LoadingTablesApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: LoadingTablesCreateEditForm,
                getCreate: LoadingTablesApi.create,
                getClone: LoadingTablesApi.getOne,
                defaults: { table: [], type: "precise" },
                successMessage: "LoadingTables Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}