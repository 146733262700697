import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import renderHTML from 'react-render-html';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import UserReview from './UserReview';

const ProductsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Products Details"}
                >


                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: 'ModelLink'
                            },
                            {
                                label: "Store ID",
                                key: "storeId",
                            },
                            {
                                label: "Product Description",
                                key: "productDescription",
                                format: "html",
                            },
                            {
                                label: "More Information",
                                key: "moreInformation",
                                format: "html",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Product Type",
                                key: "productType",
                                format: "titleCase"
                            },
                            {
                                label: "Product Preview",
                                key: "preview.fileId",
                                format: "image",
                                filters: {
                                    width: 60,
                                    height: 60
                                },
                                link: true
                            },
                            {
                                label: "Gallery",
                                key: "gallery",
                                format: "image",
                                filters: {
                                    width: 60,
                                    height: 60
                                },
                                link: true
                            },
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Has Sizing Guide",
                                key: "hasSizingGuide",
                                format: "bool"
                            },
                        ]}
                    />

                    {data.sizing &&
                        <>
                            <Seperator
                                heading={"Sizing"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data.sizing}
                                columnA={[
                                    {
                                        label: "Label",
                                        key: "label"
                                    }
                                ]}
                                columnB={[
                                    {
                                        label: "Inches",
                                        key: "inches"
                                    }
                                ]}
                                columnC={[
                                    {
                                        label: "CM",
                                        key: "cm"
                                    }
                                ]}
                                columnD={[
                                    {
                                        label: "Stock",
                                        key: "outOfStock",
                                        render: (value, row) => {

                                            return (value) ? "Out of Stock" : "In Stock";

                                        }
                                    }
                                ]}
                            />
                        </>
                    }


                    <Seperator
                        heading={"Store Data"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    {data.data &&
                        <MetaTable
                            data={data.data}
                            columnA={[
                                {
                                    label: "Name",
                                    key: "name",
                                    format: 'titleCase'
                                },
                                {
                                    label: "Slug",
                                    key: "slug",
                                },
                                {
                                    label: "Permalink",
                                    key: "permalink",
                                    render: function (value, data) {

                                        return (
                                            <a rel="noopener noreferrer" target="_blank" href={value}>{value}</a>
                                        )

                                    }
                                },
                                {
                                    label: "Sku",
                                    key: "sku",
                                },
                                {
                                    label: 'Average Rating',
                                    key: 'average_rating'
                                },
                                {
                                    label: "Rating Count",
                                    key: "rating_count",
                                },
                                {
                                    label: "Status",
                                    key: "status",
                                    format: "titleCase"
                                },
                                {
                                    label: "Images",
                                    key: "images",
                                    render: function (value, data) {

                                        return (
                                            <>
                                                {(value || []).map((v, index) => (

                                                    <React.Fragment key={index}>

                                                        <a href={v} rel="noopener noreferrer" target="_blank" className={'media-image-link '}>

                                                            <img src={v} alt="" width="60" height="60" className={'media-image'} />

                                                        </a>

                                                    </React.Fragment>

                                                ))}
                                            </>
                                        )

                                    }
                                }
                            ]}
                            columnB={[
                                {
                                    label: "Regular Prices",
                                    key: "regular_prices",
                                    render: function (value, data) {

                                        return (
                                            <>

                                                {Object.keys(value || {}).map((currency, index) => (

                                                    <React.Fragment key={index}>

                                                        {currency + ': ' + value[currency]}

                                                        <br />

                                                    </React.Fragment>

                                                ))}

                                            </>
                                        )

                                    }
                                },
                            ]}
                            columnC={[
                                {
                                    label: "Sale Prices",
                                    key: "sale_prices",
                                    render: function (value, data) {

                                        return (
                                            <>

                                                {Object.keys(value || {}).map((currency, index) => (

                                                    <React.Fragment key={index}>

                                                        {currency + ': ' + value[currency]}

                                                        <br />

                                                    </React.Fragment>

                                                ))}

                                            </>
                                        )

                                    }
                                },

                            ]}
                        />
                    }

                    <Seperator
                        heading={"User Reviews"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <Row gutter={'auto'}>

                        {data && data.data && data.data.user_reviews &&

                            data.data.user_reviews.map((item: any, index) => (

                                <Col col={{ xs: 24, md: 8 }} key={index} >

                                    <UserReview
                                        name={item.name}
                                        review={item.review}
                                        rating={item.rating}
                                    />

                                </Col>

                            ))
                        }

                    </Row>

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ProductsSingleView;
