import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ProductsApi from './ProductsApi';

let settings;

export default settings = {
    key: 'content/products',
    zone: 'content',
    path: '/content/products',
    title: "Products",
    endpoint: ProductsApi.endpoint,
    modelName: 'Products',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewProducts"],
    viewCaps: ["viewProducts"],
    createCaps: ["createProducts"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Draft",
            //     path: settings.path + "/status/draft",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Preview",
            //     path: settings.path + "/status/preview",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Live",
            //     path: settings.path + "/status/live",
            //     caps: settings.indexCaps
            // },
            {
                label: "Re-Order",
                path: settings.path + "/reorder",
                caps: settings.createCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ProductsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: [], order: ["order asc", 'created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title', 'storeId', 'status'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Store ID",
                key: "storeId",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "draft", label: "Draft" },
                    { value: "preview", label: "Preview" },
                    { value: "live", label: "Live" },
                ]
            },
            {
                label: "Product Type",
                key: "productType",
                type: "choice",
                options: [
                    { value: "normal", label: "Normal" },
                    { value: "combo", label: "Combo" },
                ]
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Store ID",
                key: "storeId",
            },
            {
                label: "Product Type",
                key: "productType",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ProductsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: ProductsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};