import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import PagesApi from './PagesApi';

let settings;

export default settings = {
    key: 'content/pages',
    zone: 'content',
    path: '/content/pages',
    title: "Pages",
    endpoint: PagesApi.endpoint,
    modelName: 'Pages',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewPages"],
    viewCaps: ["viewPages"],
    createCaps: ["createPages"],
    actions: ['trash', 'clone'],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "About",
                path: settings.path + "/section/about",
                caps: settings.indexCaps
            },
            {
                label: "User Guide",
                path: settings.path + "/section/userGuide",
                caps: settings.indexCaps
            },
            {
                label: "Other",
                path: settings.path + "/section/other",
                caps: settings.indexCaps
            },
            {
                label: "Re-Order",
                path: settings.path + "/reorder",
                caps: settings.createCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: PagesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ["parent"], order: ['order ASC', 'title ASC'], limit: 1000, skip: 0 };

        if (routeName === "content/pagesSort") filters.where.tree = true;

        if (params && params.status) filters.where.status = params.status;
        if (params && params.section) filters.where.section = params.section;

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title', 'status', 'section'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "preview", label: "Preview" },
                    { value: "live", label: "Live" },
                ]
            },
            {
                label: "Section",
                key: "section",
                type: "choice",
                options: [
                    { value: "about", label: "About" },
                    { value: "userGuide", label: "User Guide" },
                    { value: "other", label: "Pther" },
                ]
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
                sort: "title",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Parent",
                key: "parent",
                format: "ModelLink",
            },
            // {
            //     label: "Order",
            //     key: "order",
            //     format: "number",
            // },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: PagesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: PagesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["parent"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};