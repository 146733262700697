import React, { useMemo } from 'react';
import renderHTML from 'react-render-html';
import { Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MediaImage from '@dex/bubl-dash/src/lib/components/MediaImage/MediaImage';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

import styles from "./RenderContentBlocks.module.scss";

const RenderBlocks: React.FC<any> = (props: any) => {

    let { blocks, text } = props;

    console.log('blocks', blocks);

    if (!blocks && text) {

        blocks = [{ type: "text", content: text }];

    }

    return useMemo(() => {

        return (
            <div style={{ maxWidth: props.width || 640 }}>
                {blocks.map((block, index) => (
                    <React.Fragment key={index}>

                        {index > 0 &&
                            <Space />
                        }

                        {block.type === "text" &&
                            <RenderContentText block={block} />
                        }

                        {block.type === "sizeGuide" &&
                            <RenderContentText block={block} />
                        }

                        {block.type === "image" &&
                            <RenderContentImage block={block} />
                        }

                        {block.type === "video" &&
                            <RenderContentVideo block={block} />
                        }

                        {block.type === "checklist" &&
                            <RenderContentCheckList block={block} />
                        }

                        {block.type === "teamMember" &&
                            <RenderContentTeamMember block={block} />
                        }

                        {block.type === "contentSlider" &&
                            <RenderContentContentSlider block={block} />
                        }

                        {block.type === "staticComponent" &&
                            <RenderContentStaticComponent block={block} />
                        }

                        {block.type === "heading" &&
                            <RenderContentHeading block={block} />
                        }

                        {block.type === "contentSpotlight" &&
                            <RenderContentSpotLight block={block} />
                        }

                    </React.Fragment>
                ))}
            </div>
        )

    }, [blocks]);

}

const RenderContentText: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='rich-text-view'>
                {renderHTML(block.text)}
            </div>
        )

    }, [block]);

}


const RenderContentImage: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (

            <div className='responsive'>
                <MediaImage id={block.image.fileId} filters={{ width: 1280 }} link={true} />
                <p className={styles.imageText}>{block.caption}</p>
            </div>
        )

    }, [block]);

}

const RenderContentVideo: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='responsive'>
                {block.video.source === "youtube" &&
                    <iframe title={block.video.id} className="ytplayer" width="640" height="360" src={"https://www.youtube.com/embed/" + block.video.id + "?autoplay=0"}></iframe>
                }
                {block.video.source === "facebook" &&
                    <iframe title={block.video.id} className="fbplayer" width="640" height="360" src={"https://www.facebook.com/video/embed?video_id=" + block.video.id}></iframe>
                }
            </div>
        )

    }, [block]);

}

const RenderContentCheckList: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <div className={styles.checkWrap}>

                    <h1 className={styles.headingCheck}>{block.heading}</h1>

                    {block.checklist.length > 1 &&
                        block.checklist.map((item, key) => (

                            <p key={key}>— {item.question}</p>

                        ))
                    }

                    <p className={styles.footerCheck}>{block.footer}</p>

                </div>

            </div>
        )

    }, [block])
}

const RenderContentTeamMember: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">
                {block.photo &&
                    <MediaImage id={block.photo.fileId} filters={{ width: 150 }} link={true} />
                }
                <h1>{block.name}</h1>
                <p>{block.designation}</p>
            </div>
        )

    }, [block])
}

const RenderContentContentSlider: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <Row
                    gutter={'auto'}
                >

                    {block.slides.map((slide, key) => (

                        <Col
                            col={{ xs: 12, md: 12 }}
                            key={key}
                            className={styles.colWrap}
                        >

                            <MediaImage id={slide.image?.fileId} filters={{ width: 720, height: 720 }} link={true} />
                            <div className={styles.contentText}>
                                <h3 className={styles.contentTextHead}>Slide {key + 1}</h3>
                                <p className={styles.contentTextDesc}>{slide.title}</p>
                            </div>

                        </Col>
                    ))}

                </Row>

            </div>
        )
    }, [block])
}

const RenderContentStaticComponent: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <div className={styles.staticCmp}>

                    <p>{block.name}</p>
                    <p>Props : {block.props}</p>

                </div>

            </div>
        )
    }, [block])
}

const RenderContentHeading: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <h1 className={styles.heading}>{block.heading}</h1>

                <p className={styles.subHeading}>{block.subHeading}</p>
            </div>
        )
    }, [block])
}

const RenderContentSpotLight: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <Row>

                    {block.spotlights.map((item, key) => (

                        <Col col={{ xs: 8 }} key={key} className={styles.spotCol}>

                            <h1>{item.title}</h1>
                            <p>{item.text}</p>
                            <p>{item.caption}</p>

                        </Col>
                    ))}

                </Row>

            </div>
        )

    }, [block])
}

export default RenderBlocks;
