import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';


const VideosSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    console.log("data=>>>", data)

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Videos Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",

                            },

                            {
                                label: "Description",
                                key: "description",
                            },
                        ]}
                        columnB={[

                            {
                                label: "Source",
                                key: 'source',
                            },

                            {
                                label: "Status",
                                key: 'status',
                            },
                            {
                                label: "Video Topics",
                                key: 'topic',
                                format: "modelLink"
                            },

                            {
                                label: "Activity",
                                key: "activity",
                                format: "modelLink"
                            },

                        ]}
                    // columnC={[
                    //     {
                    //         label: "Video",
                    //         key: "video.fileId",
                    //         format: "image",
                    //         filters: {
                    //             width: 60,
                    //             height: 60
                    //         },
                    //         link: true
                    //     },

                    // ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default VideosSingleView;
