import AvatarsIndex from './AvatarsIndex';
import AvatarsCreate from './AvatarsCreate';
import AvatarsSettings from './AvatarsSettings';
import AvatarsSingle from './AvatarsSingle';

export default function (app) {

    const settings = AvatarsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }

        ],
    });

    AvatarsIndex(app, settings);

    AvatarsCreate(app, settings);

    AvatarsSingle(app, settings);

}