import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import AppActivityApi from './AppActivityApi';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'appactivity',
    zone: 'users',
    path: '/appactivity',
    title: "AppActivity",
    endpoint: AppActivityApi.endpoint,
    modelName: 'AppActivity',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewAppActivity"],
    viewCaps: ["viewAppActivity"],
    createCaps: ["createAppActivity"],
    updateCaps: ["updateAppActivity"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: AppActivityApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['user'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "User",
                key: "userId",
                type: "relationship",
                model: "Users",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "",
                key: "this",
                render: (value, data) => {
                    return <ModelLink modelName="AppActivity" data={{ id: data.id, title: "View Activity" }} />
                }
            },
            {
                label: "User",
                key: 'user',
                format: "ModelLink"
            },
            {
                label: "Action",
                key: "action",
            },
            {
                label: "Type",
                key: "type",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: AppActivityApi,
                settings: settings,
            }
        ];

        return columns;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
        ];

        return formats;

    },
    singleFetch: AppActivityApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['user'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data.user.fullName;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            // {
            //     label: "Edit",
            //     path: path + "/edit",
            //     caps: settings.updateCaps
            // },
        ]

    },
};