import { Events } from '@dex/bubl-dash';
import React, { useEffect, useMemo, useRef } from 'react';
import postMessage from "../../../assets/functions/postMessage";

const AvatarsPreview: React.FC<any> = (props: any) => {

    const { config, bodyType } = props;

    const frameRef = useRef()


    useEffect(() => {

        //console.log(frameRef.current.contentWindow)

        postMessage.listen("connect", () => {

        })

        setTimeout(() => {
            postMessage.send({
                name: "buildMe",
                config: config,
                bodyType: bodyType,
            });
        }, 2000)




    }, [config, bodyType])

    return useMemo(() => (

        <div>

            <iframe
                ref={frameRef}
                src={process.env.REACT_APP_AVATAR_ENDPOINT + "/see"}
                name="save"
                height="70vh"
                width="100%"
                style={{ height: '70vh' }}
                sandbox="allow-forms allow-modals allow-popups allow-scripts allow-same-origin"
            >

            </iframe>

        </div>


    ), [props]);

}

export default AvatarsPreview;
