import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRelationship } from '@dex/bubl-dash';
import { FieldRadio } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { FieldSelect } from '@dex/bubl-dash';


const AppActivityCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"App Activity Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 22 }}>
                                <FieldRelationship
                                    form={form}
                                    label={'User'}
                                    name={'userId'}
                                    isMulti={false}
                                    required={false}
                                    preload={true}
                                    endpoint={'Users'}
                                    defaultValue={form.defaults.userId}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 22 }}>

                                <FieldText
                                    form={form}
                                    label={"Action"}
                                    name='action'
                                    required={false}
                                    defaultValue={form.defaults.action}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 22 }}>

                                <FieldText
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={false}
                                    defaultValue={form.defaults.type}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 22 }}>

                                <FieldText
                                    form={form}
                                    label={"Model Name"}
                                    name='modelName'
                                    required={false}
                                    defaultValue={form.defaults.modelName}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 22 }}>

                                <FieldText
                                    form={form}
                                    label={"Model Id"}
                                    name='modelId'
                                    required={false}
                                    defaultValue={form.defaults.modelId}
                                />

                            </Col>

                        </Row>
                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AppActivityCreateEditForm;
