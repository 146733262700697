/* eslint-disable react-app/react/jsx-no-undef */
import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import TrainingProgramsFormSession from './TrainingProgramsFormSession';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import TrainingProgramsFormGuidePage from './TrainingProgramsFormGuidePage';

const TrainingProgramsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Training Programs Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Display Title"}
                                    name='displayTitle'
                                    required={false}
                                    defaultValue={form.defaults.displayTitle}
                                    placeholder={"Defaults to title"}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport / Activity"}
                                    name='activityId'
                                    isMulti={false}
                                    required={true}
                                    preload={true}
                                    endpoint={'SportsActivities'}
                                    defaultValue={form.defaults.activityId}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Short Description"}
                                    name='description'
                                    required={true}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>


                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Long Description"}
                                    placeholder={"Optional, will use short description by default."}
                                    name='longDescription'
                                    rows={2}
                                    required={false}
                                    defaultValue={form.defaults.longDescription}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "live", label: "Live" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Format"}
                                    name='format'
                                    required={true}
                                    defaultValue={form.defaults.format}
                                    options={[
                                        { value: "dailyProgram", label: "Daily Program" },
                                        { value: "starterGuide", label: "Starter Guide" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                {form.values.format === "dailyProgram" &&
                    <React.Fragment key={"dailyPrograms"}>

                        <Seperator
                            heading="Daily Training Plan"
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <FieldRepeater
                            form={form}
                            name={'dailyPrograms'}
                            itemLabel={"Week"}
                            addButtonLabel={"Add Week"}
                            defaultValues={{ sessions: [{}, {}, {}, {}, {}, {}, {}] }}
                            collapseAll={true}
                        >

                            {form.values.dailyPrograms.map((row, n) => (

                                <Row gutter={8} edge={true} key={form.key + "-" + n}>

                                    <Col col={{ xs: 24 }}>

                                        <FieldSwitch
                                            form={form}
                                            label={"Enable Week Breifing"}
                                            name={`dailyPrograms[${n}].enableBreif`}
                                            defaultValue={row.enableBreif}
                                        />

                                    </Col>

                                    {row.enableBreif &&
                                        <>

                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"Heading"}
                                                    name={`dailyPrograms[${n}].breifHeading`}
                                                    required={true}
                                                    defaultValue={row.breifHeading}
                                                />

                                                <FieldText
                                                    form={form}
                                                    label={"Sub Heading"}
                                                    name={`dailyPrograms[${n}].breifSubHeading`}
                                                    defaultValue={row.breifSubHeading}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldUpload
                                                    form={form}
                                                    folder={'admin-media'}
                                                    limit={1}
                                                    label={"Video"}
                                                    name={`dailyPrograms[${n}].breifVideo`}
                                                    defaultValue={row.breifVideo}
                                                />

                                            </Col>

                                        </>
                                    }

                                    <Col col={{ xs: 24 }}>

                                        <FieldList
                                            form={form}
                                            name={`dailyPrograms[${n}].sessions`}
                                            required={true}
                                            label={"Sessions"}
                                            addButtonLabel={"Add Session"}
                                            min={7}
                                            max={7}
                                        >

                                            {(row.sessions || []).map((session, i) => (

                                                <TrainingProgramsFormSession
                                                    key={i}
                                                    form={form}
                                                    session={session}
                                                    weekN={n}
                                                    sessionN={i}
                                                />

                                            ))}

                                        </FieldList>

                                    </Col>

                                </Row>

                            ))}

                        </FieldRepeater>

                    </React.Fragment>
                }


                {form.values.format === "starterGuide" &&
                    <React.Fragment key={"starterGuide"}>

                        <Seperator
                            heading="Starter Guide"
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldList
                                    form={form}
                                    name={`starterGuide`}
                                    required={true}
                                    label={"Starter Guide"}
                                    addButtonLabel={"Add Starter Guide Page"}
                                    min={1}
                                    max={6}
                                >

                                    {(form.values.starterGuide || []).map((page, i) => (

                                        <TrainingProgramsFormGuidePage
                                            key={i}
                                            form={form}
                                            page={page}
                                            prefix={`starterGuide[${i}]`}
                                        />

                                    ))}

                                </FieldList>

                            </Col>

                        </Row>

                    </React.Fragment>
                }


                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TrainingProgramsCreateEditForm;
