import UsersSingleViewMain from './UsersSingleViewMain';
import UsersSingleEditMain from './UsersSingleEditMain';

import { loop } from '@dex/bubl-helpers';

export default function (app) {

    app.mods.UsersSingleViewMain = UsersSingleViewMain;
    app.mods.UsersSingleEditMain = UsersSingleEditMain;

    app.mods.UsersSettings = (settings) => {

        const _singleFetchParams = settings.singleFetchParams;

        settings.singleFetchParams = (routeName, params, user, fetch) => {

            const filters = _singleFetchParams(routeName, params, user, fetch);

            filters.include = [...filters.include];

            return filters;

        }

        return settings;

    };

}