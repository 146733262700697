import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const LoadingTablesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Loading Table Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                            {
                                label: "Placement",
                                key: "placement"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Exogen",
                                key: "exogen"
                            },
                        ]}
                    />

                    {data.table &&
                        <>
                            <Seperator
                                heading={"Tables"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data.table}
                                columnA={[
                                    {
                                        label: "Body Weight Min",
                                        key: "bodyWeightMin",
                                        format: "number",
                                        suffix: " KG"
                                    },
                                    {
                                        label: "Body Weight Max",
                                        key: "bodyWeightMax",
                                        format: "number",
                                        suffix: " KG"
                                    }
                                ]}
                                columnB={[
                                    {
                                        label: "Load Weight",
                                        key: "loadWeight",
                                        format: "number",
                                        suffix: " G"
                                    }
                                ]}
                                columnC={[
                                    {
                                        label: "Thumbnail",
                                        key: "thumbnail",
                                        format: "image",
                                        link: true,
                                        filters: { width: 60, height: 60 }
                                    }
                                ]}
                            />
                        </>
                    }

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default LoadingTablesSingleView;
