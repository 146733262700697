import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const TrainingProgressSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Training Progress Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Program",
                                key: "program",
                                format: "ModelLink",
                            },
                            {
                                label: "User",
                                key: "user",
                                format: "ModelLink",
                            },
                            {
                                label: "Session Title",
                                key: "sessionTitle"
                            },
                            {
                                label: "Completed",
                                key: "completed",
                                format: "switch",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Week No",
                                key: "weekNo",
                            },
                            {
                                label: "Day No",
                                key: "dayNo",
                            },
                            {
                                label: "Duration",
                                key: "duration",
                            },
                            {
                                label: "Date Time Completed",
                                key: "dateTimeCompleted",
                                format: "date",
                            }
                        ]}
                        columnC={[
                            {
                                label: "RPE",
                                key: "rpe",
                            },
                            {
                                label: "RPE Average",
                                key: "rpeAverage",
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default TrainingProgressSingleView;
