import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const TrainingDataSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Training Data Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Data",
                                key: "data",
                            },
                            {
                                label: "DataPoints",
                                key: "dataPoint",
                                format: "ModelLink",
                            },
                            {
                                label: "Users",
                                key: "user",
                                format: "ModelLink",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Slug",
                                key: "slug"
                            },
                            {
                                label: "Session",
                                key: "session",
                                format: "ModelLink",
                            },
                            {
                                label: "Program",
                                key: "program",
                                format: "ModelLink",
                            },
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default TrainingDataSingleView;
