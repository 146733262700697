import React, { useCallback, useEffect, useMemo } from 'react';
import ProductsApi from '../ProductsApi';

import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Sort from '@dex/bubl-dash/src/lib/components/Sort/Sort';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Loading from '@dex/bubl-dash/src/lib/components/Loading/Loading';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const ProductsSort: React.FC<any> = (props: any) => {

    const app = useApp();

    const form = useForm({ section: "other" });

    const Products = ProductsApi.get();

    const save = ProductsApi.create({}, "reorder");

    useEffect(() => {

        const params: any = { where: {}, include: [], order: ["order ASC"], limit: 1000 };

        Products.reset();
        Products.cancel();

        Products.run({
            params: params
        });

    }, [form.values.section]);

    const handleSave = useCallback((data) => {

        save.run({
            data: { rows: data },
            onComplete: (response: any) => {

                app.alert(response, 'success');

            }
        });

    }, []);

    return useMemo(() => (

        <Box gutter={"auto"}>

            <Panel
                heading={"Re-Order"}
            >

                {Products.loading &&
                    <Box gutter={"auto"}>
                        <br />
                        <Loading />
                    </Box>
                }

                {Products.data && Products.data.data && Products.data.data.length > 0 &&

                    <Sort
                        data={Products.data.data}
                        // parentIdKey={"parentId"}
                        preSorted={true}
                        maxDepth={1}
                        handleSave={handleSave}
                        loading={save.loading}
                    />

                }

            </Panel>

        </Box>

    ), [form.values, Products.loading, save.loading]);

}

export default ProductsSort;
