import React, { useCallback, useEffect, useMemo } from 'react';
import SportsActivitiesApi from '../SportsActivitiesApi';

import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Sort from '@dex/bubl-dash/src/lib/components/Sort/Sort';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Loading from '@dex/bubl-dash/src/lib/components/Loading/Loading';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const SportsActivitiesSort: React.FC<any> = (props: any) => {

    const app = useApp();

    const form = useForm();

    const pages = SportsActivitiesApi.get();

    const save = SportsActivitiesApi.create({}, "reorder");

    useEffect(() => {

        const params: any = { where: {}, include: [], order: ["order ASC"], limit: 1000 };

        pages.reset();
        pages.cancel();

        pages.run({
            params: params
        });

    }, [form.values.section]);

    const handleSave = useCallback((data) => {

        save.run({
            data: { rows: data },
            onComplete: (response: any) => {

                app.alert(response, 'success');

            }
        });

    }, []);

    return useMemo(() => (

        <Box gutter={"auto"}>

            <Panel
                heading={"Re-Order"}
            >

                {pages.loading &&
                    <Box gutter={"auto"}>
                        <br />
                        <Loading />
                    </Box>
                }

                {pages.data && pages.data.data && pages.data.data.length > 0 &&

                    <Sort
                        data={pages.data.data}
                        // parentIdKey={"parentId"}
                        preSorted={true}
                        maxDepth={1}
                        handleSave={handleSave}
                        loading={save.loading}
                    />

                }

            </Panel>

        </Box>

    ), [form.values, pages.loading, save.loading]);

}

export default SportsActivitiesSort;
