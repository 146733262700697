import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import TrainingProgressSettings from '../../TrainingProgress/TrainingProgressSettings';

const UsersProgressPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        const filters: any = { where: { userId: data.id }, include: ['program', 'user'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    }

    return useMemo(() => (

        <>
        
            <IndexTable
                title={"Training Progress"}
                getFetch={TrainingProgressSettings.indexFetch}
                getColumns={TrainingProgressSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={TrainingProgressSettings.indexQuickSearch}
                getSearchFields={TrainingProgressSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default UsersProgressPanel;
