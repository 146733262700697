import React, { useMemo } from 'react'
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const UserReview: React.FC<any> = (props: any) => {

    const { name, review, rating } = props;

    return useMemo(() => (

        <dl>

            <dt>Name<span className="label-cln">:</span> {name}</dt>

            <Space height={'small'} />

            <dt>Review<span className="label-cln">:</span></dt>
            <dd>{review}</dd>

            <Space height={'small'} />

            <dt>Rating<span className="label-cln">:</span> {rating}</dt>

            <Space height={'small'} />

        </dl>

    ), [props]);

}

export default UserReview;
