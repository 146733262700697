/* eslint-disable react-app/react/jsx-no-undef */
import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import TrainingProgramsFormDataPoint from './TrainingProgramsFormDataPoint';

const TrainingProgramsFormSession: React.FC<any> = (props: any) => {

    const { form, session, weekN, sessionN } = props;

    const prefix = `dailyPrograms[${weekN}].sessions[${sessionN}]`;

    return useMemo(() => (

        <FieldPopup
            buttonLabel={"Edit Day " + (sessionN + 1) + (session.title ? ` - ${session.title}` : "")}
            heading={`Week ${weekN + 1} Day ${sessionN + 1}`}
            form={form}
            name={prefix}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"Session Title"}
                                name={`${prefix}.title`}
                                required={true}
                                defaultValue={session.title}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldText
                                form={form}
                                label={"Description"}
                                name={`${prefix}.description`}
                                defaultValue={session.description}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <TrainingProgramsFormDataPoint
                                form={form}
                                label={"Training Data (Before Session)"}
                                prefix={`${prefix}.dataPointBefore`}
                                point={session.dataPointBefore || {}}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <TrainingProgramsFormDataPoint
                                form={form}
                                label={"Training Data (After Session)"}
                                prefix={`${prefix}.dataPointAfter`}
                                point={session.dataPointAfter || {}}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label="Format"
                                required={true}
                                name={`${prefix}.format`}
                                defaultValue={session.format}
                                options={[
                                    { label: "Timed Workout", value: "timmedWorkout" },
                                    { label: "Un-Timed Workout", value: "unTimedWorkout" },
                                    { label: "Rest Day", value: "restDay" },
                                ]}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldSwitch
                                form={form}
                                label="Has Loading Guide"
                                name={`${prefix}.hasLoading`}
                                defaultValue={session.hasLoading}
                            />

                        </Col>

                        {session.hasLoading &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={`Loading Tables`}
                                    name={`${prefix}.loadingTablesIds`}
                                    isMulti={true}
                                    required={true}
                                    preload={true}
                                    endpoint={'LoadingTables'}
                                    defaultValue={session.loadingTablesIds}
                                />

                            </Col>
                        }

                    </Row>

                </Col>

            </Row>


            <Seperator
                heading="Session Guide"
                top={"auto"}
                bottom={"auto"}
            />

            <FieldRepeater
                form={form}
                name={`${prefix}.guide`}
                addButtonLabel={"Add Instruction"}
                collapseAll={true}
            >

                {(session.guide || []).map((instruction, j) => (

                    <React.Fragment key={form.key + "-" + j}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"Title"}
                                            name={`${prefix}.guide[${j}].title`}
                                            required={true}
                                            defaultValue={instruction.title}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 20 }}>

                                        <FieldTextArea
                                            form={form}
                                            label={"Description"}
                                            name={`${prefix}.guide[${j}].description`}
                                            required={false}
                                            defaultValue={instruction.description}
                                        />

                                    </Col>

                                    <Col col={{ xs: 12, md: 4 }}>

                                        <FieldText
                                            form={form}
                                            label={"Duration"}
                                            name={`${prefix}.guide[${j}].duration`}
                                            required={false}
                                            defaultValue={instruction.duration}
                                        />

                                    </Col>

                                </Row>

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`${prefix}.guide[${j}].thumbnail`}
                                    required={false}
                                    defaultValue={instruction.thumbnail}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </React.Fragment>

                ))}

            </FieldRepeater>

        </FieldPopup>

    ), [props]);

}

export default TrainingProgramsFormSession;
