import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DeletePanel from '@dex/bubl-dash/src/lib/components/SinglePanels/DeletePanel';
import AvatarsApi from '../AvatarsApi';
import AvatarsCreateEditForm from './AvatarsCreateEditForm';

const AvatarsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = AvatarsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Avatars Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        console.log("values=> ", values)

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <AvatarsCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>

        </Row>

    ), [data, form, update]);

}

export default AvatarsSingleEdit;
