import TrainingProgressIndex from './TrainingProgressIndex';
import TrainingProgressCreate from './TrainingProgressCreate';
import TrainingProgressSettings from './TrainingProgressSettings';
import TrainingProgressSingle from './TrainingProgressSingle';

export default function (app) {

    const settings = TrainingProgressSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 20,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // }
        ],
    });

    TrainingProgressIndex(app, settings);

    TrainingProgressCreate(app, settings);

    TrainingProgressSingle(app, settings);

}