import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';

const ProductsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Products Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Store ID"}
                                    name='storeId'
                                    required={true}
                                    defaultValue={form.defaults.storeId}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRich
                                    form={form}
                                    label={"Product Description"}
                                    name={'productDescription'}
                                    required={false}
                                    defaultValue={form.defaults.productDescription}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRich
                                    form={form}
                                    label={"More Information"}
                                    name={'moreInformation'}
                                    required={false}
                                    defaultValue={form.defaults.moreInformation}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "live", label: "Live" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Product Type"}
                                    name='productType'
                                    required={true}
                                    defaultValue={form.defaults.productType}
                                    options={[
                                        { value: "normal", label: "Normal" },
                                        { value: "combo", label: "Combo" },
                                    ]}
                                />

                            </Col>

                            {form.values.productType === "combo" &&
                                <Col col={{ xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Combo Products"}
                                        name='comboProductsIds'
                                        required={true}
                                        preload={true}
                                        defaultValue={form.defaults.comboProductsIds}
                                        endpoint={"Products"}
                                        where={{ productType: "normal" }}
                                        isMulti={true}
                                    />

                                </Col>
                            }

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Product Preview"}
                                    name={`preview`}
                                    required={false}
                                    defaultValue={form.defaults.preview}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Gallery"}
                                    name={`gallery`}
                                    required={false}
                                    defaultValue={form.defaults.gallery}
                                    limit={50}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Sizing Guide"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldSwitch
                            form={form}
                            label="Has Sizing Guide"
                            name="hasSizingGuide"
                            required={false}
                            defaultValue={form.defaults.hasSizingGuide}
                        />

                    </Col>

                </Row>

                {form.values.hasSizingGuide &&

                    <>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Sizing Attribute Key"}
                                    name='sizingAttrKey'
                                    required={true}
                                    defaultValue={form.defaults.sizingAttrKey}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Sizing Attribute Label"}
                                    name='sizingAttrLabel'
                                    required={true}
                                    defaultValue={form.defaults.sizingAttrLabel}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Sizing Instructions Image"}
                                    name={`sizingImage`}
                                    required={!form.values.sizingVideo}
                                    defaultValue={form.defaults.sizingImage}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Sizing Instructions Video"}
                                    name={`sizingVideo`}
                                    required={!form.values.sizingImage}
                                    defaultValue={form.defaults.sizingVideo}
                                />

                            </Col>

                        </Row>

                        <FieldRepeater
                            form={form}
                            name={'sizing'}
                            addButtonLabel={"Add Size"}
                        >

                            {form.values.sizing.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldText
                                                form={form}
                                                label={"Size Label"}
                                                name={`sizing[${n}].label`}
                                                required={true}
                                                defaultValue={row.label}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldText
                                                form={form}
                                                label={"Inches"}
                                                name={`sizing[${n}].inches`}
                                                required={true}
                                                defaultValue={row.inches}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldText
                                                form={form}
                                                label={"CM"}
                                                name={`sizing[${n}].cm`}
                                                required={true}
                                                defaultValue={row.cm}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldSwitch
                                                form={form}
                                                label={"Out of Stock"}
                                                name={`sizing[${n}].outOfStock`}
                                                defaultValue={row.outOfStock}
                                            />

                                        </Col>

                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </>
                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ProductsCreateEditForm;
