import SportsActivitiesIndex from './SportsActivitiesIndex';
import SportsActivitiesCreate from './SportsActivitiesCreate';
import SportsActivitiesSettings from './SportsActivitiesSettings';
import SportsActivitiesSingle from './SportsActivitiesSingle';

export default function (app) {

    const settings = SportsActivitiesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            /*{
               label: "Draft",
               path: settings.path + "/status/draft",
               caps: settings.indexCaps
           },
           {
               label: "Preview",
               path: settings.path + "/status/preview",
               caps: settings.indexCaps
           },
           {
               label: "Live",
               path: settings.path + "/status/live",
               caps: settings.indexCaps
           },*/
            {
                label: "Re-Order",
                path: settings.path + "/reorder",
                caps: settings.createCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    SportsActivitiesIndex(app, settings);

    SportsActivitiesCreate(app, settings);

    SportsActivitiesSingle(app, settings);

}