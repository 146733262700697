import React from 'react';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import { Row, Col, Space, Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { get } from '@dex/bubl-helpers';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldBlocksSwitchTabs from './FieldBlocksSwitchTabs';

const FieldBlocks: React.FC<any> = (props: any) => {

    const { form, name } = props;

    let values = form.getValue(name) || [];

    const disabled = props.disabled || [];

    const defaultValues = props.defaultValues || {};

    if (!defaultValues.checklist) defaultValues.checklist = [];
    if (!defaultValues.innerBlocks) defaultValues.innerBlocks = [];

    return (
        <>

            <FieldRepeater
                addButtonLabel={"Add Block"}
                collapseAll={true}
                primaryName={"type"}
                {...props}
                defaultValues={defaultValues}
            >

                {(values || []).map((row, n) => (

                    <React.Fragment key={form.key + "-" + n}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Block Type"}
                                    name={`${name}[${n}].type`}
                                    required={true}
                                    defaultValue={row.type}
                                    options={[
                                        { value: 'text', label: "Text" },
                                        { value: 'heading', label: "Heading" },
                                        { value: 'image', label: "Image" },
                                        { value: 'video', label: "Video" },
                                        { value: 'space', label: "Space" },
                                        // { value: 'teamMember', label: "Team Member" },
                                        { value: 'checklist', label: "Checklist / Tips" },
                                        { value: 'contentSlider', label: "Content Slider" },
                                        { value: 'contentSpotlight', label: "Content Spotlight" },
                                        disabled.includes("accordion") ? undefined : { value: 'accordion', label: "Accordion" },
                                        disabled.includes("switchTabs") ? undefined : { value: 'switchTabs', label: "Switch Tabs" },
                                        disabled.includes("pageContent") ? undefined : { value: 'pageContent', label: "Page Content" },
                                        disabled.includes("loadingGuide") ? undefined : { value: 'loadingGuide', label: "Loading Guide" },
                                        // { value: 'staticComponent', label: "Static Component" },
                                    ].filter(Boolean)}
                                />

                            </Col>

                        </Row>

                        {row.type === "text" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRich
                                        form={form}
                                        label={"Text"}
                                        name={`${name}[${n}].text`}
                                        required={true}
                                        defaultValue={row.text}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "image" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Image"}
                                        name={`${name}[${n}].image`}
                                        required={true}
                                        defaultValue={row.image}
                                        limit={1}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Caption"}
                                                name={`${name}[${n}].caption`}
                                                required={false}
                                                defaultValue={row.caption}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Background Color"}
                                                name={`${name}[${n}].style.backgroundColor`}
                                                required={false}
                                                placeholder={"transparent"}
                                                defaultValue={get(row, 'style.backgroundColor')}
                                            />

                                        </Col>

                                    </Row>

                                </Col>

                            </Row>
                        }

                        {row.type === "video" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Video"}
                                        name={`${name}[${n}].video`}
                                        required={true}
                                        defaultValue={row.video}
                                        limit={1}
                                        imageOnly={false}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Loop"}
                                        name={`${name}[${n}].loop`}
                                        defaultValue={row.loop}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Auto Play"}
                                        name={`${name}[${n}].shouldPlay`}
                                        defaultValue={row.shouldPlay}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "heading" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Sub Heading"}
                                        name={`${name}[${n}].subHeading`}
                                        required={false}
                                        defaultValue={row.subHeading}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "teamMember" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Name"}
                                        name={`${name}[${n}].name`}
                                        required={true}
                                        defaultValue={row.name}
                                    />

                                    <Space height={8} />

                                    <FieldText
                                        form={form}
                                        label={"Designation"}
                                        name={`${name}[${n}].designation`}
                                        required={false}
                                        defaultValue={row.designation}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Photo"}
                                        name={`${name}[${n}].photo`}
                                        required={false}
                                        defaultValue={row.photo}
                                        limit={1}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "checklist" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Footer"}
                                        name={`${name}[${n}].footer`}
                                        required={false}
                                        defaultValue={row.footer}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <Box gutter={{ lg: 4, xl: 4 }}>

                                        <Seperator
                                            heading="Questions"
                                            top="small"
                                            bottom="small"
                                        />

                                    </Box>

                                    <FieldRepeater
                                        form={form}
                                        name={`${name}[${n}].checklist`}
                                        defaultValue={row.checklist}
                                        addButtonLabel={"Add Question"}
                                    >

                                        {(row.checklist || []).map((item, i) => (

                                            <>

                                                <FieldText
                                                    form={form}
                                                    label={"Question"}
                                                    name={`${name}[${n}][checklist][${i}].question`}
                                                    required={true}
                                                    defaultValue={item.question}
                                                />

                                            </>

                                        ))}

                                    </FieldRepeater>

                                </Col>

                            </Row>
                        }

                        {row.type === "staticComponent" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Component Name"}
                                        name={`${name}[${n}].name`}
                                        required={true}
                                        defaultValue={row.name}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Component Props"}
                                        name={`${name}[${n}].props`}
                                        required={false}
                                        defaultValue={row.props}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "contentSlider" &&

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRadio
                                        form={form}
                                        label="Size"
                                        name={`${name}[${n}].size`}
                                        required={false}
                                        defaultValue={row.size}
                                        options={[
                                            { value: 'small', label: 'Small' },
                                            { value: 'medium', label: 'Medium' },
                                            { value: 'large', label: 'Large' },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldSwitch
                                        form={form}
                                        label="Disable Box Shadow"
                                        name={`${name}[${n}].noShadow`}
                                        defaultValue={row.noShadow}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldRepeater
                                        form={form}
                                        name={`${name}[${n}].slides`}
                                        addButtonLabel={"Add Slide"}
                                    >

                                        {(row.slides || []).map((rowSlide, rowN) => (

                                            <React.Fragment key={form.key + "-" + n}>

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 12, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Title"}
                                                            name={`${name}[${n}].slides[${rowN}].title`}
                                                            required={false}
                                                            defaultValue={rowSlide.title}
                                                        />

                                                        <Space height={8} />

                                                        <FieldTextArea
                                                            form={form}
                                                            label={"Caption"}
                                                            name={`${name}[${n}].slides[${rowN}].caption`}
                                                            required={false}
                                                            defaultValue={rowSlide.caption}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 12, md: 12 }}>

                                                        <FieldRelationship
                                                            form={form}
                                                            label={"Loading Table"}
                                                            name={`${name}[${n}].slides[${rowN}].loadingTableId`}
                                                            defaultValue={rowSlide.loadingTableId}
                                                            endpoint={"LoadingTables"}
                                                            isMulti={false}
                                                            required={false}
                                                            preload={true}
                                                        />

                                                        {!rowSlide.loadingTableId &&
                                                            <>

                                                                <Space height={8} />

                                                                <FieldUpload
                                                                    form={form}
                                                                    folder={'admin-media'}
                                                                    label={"Image / Video"}
                                                                    name={`${name}[${n}].slides[${rowN}].image`}
                                                                    required={false}
                                                                    defaultValue={rowSlide.image}
                                                                    limit={1}
                                                                />

                                                            </>
                                                        }

                                                    </Col>

                                                </Row>

                                            </React.Fragment>

                                        ))}

                                    </FieldRepeater>

                                </Col>

                            </Row>
                        }

                        {row.type === "contentSpotlight" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"No. of Columns"}
                                        name={`${name}[${n}].noOfColumns`}
                                        required={false}
                                        defaultValue={row.noOfColumns}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldRepeater
                                        form={form}
                                        name={`${name}[${n}].spotlights`}
                                        addButtonLabel={"Add Spotlight"}
                                    >

                                        {(row.spotlights || []).map((rowSpotlight, rowN) => (

                                            <React.Fragment key={form.key + "-" + n}>

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Title"}
                                                            name={`${name}[${n}].spotlights[${rowN}].title`}
                                                            required={false}
                                                            defaultValue={rowSpotlight.title}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Text"}
                                                            name={`${name}[${n}].spotlights[${rowN}].text`}
                                                            required={false}
                                                            defaultValue={rowSpotlight.text}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 8 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Caption"}
                                                            name={`${name}[${n}].spotlights[${rowN}].caption`}
                                                            required={false}
                                                            defaultValue={rowSpotlight.caption}
                                                        />

                                                    </Col>

                                                </Row>

                                            </React.Fragment>

                                        ))}

                                    </FieldRepeater>

                                </Col>

                            </Row>

                        }


                        {row.type === "space" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Height"}
                                        name={`${name}[${n}].height`}
                                        required={true}
                                        defaultValue={row.height}
                                        options={[
                                            { label: "XS (4px)", value: "4px" },
                                            { label: "S (8px)", value: "8px" },
                                            { label: "M (12px)", value: "12px" },
                                            { label: "L (16px)", value: "16px" },
                                            { label: "XL (20px)", value: "20px" },
                                            { label: "XXL (24px)", value: "24px" },
                                        ]}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "pageContent" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Page"}
                                        name={`${name}[${n}].pageId`}
                                        required={true}
                                        defaultValue={row.pageId}
                                        endpoint={"Pages"}
                                        preload={true}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "accordion" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Sub Heading"}
                                        name={`${name}[${n}].subHeading`}
                                        required={false}
                                        defaultValue={row.subHeading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldBlocks
                                        form={form}
                                        addButtonLabel={"Inner Content Block"}
                                        name={`${name}[${n}].innerBlocks`}
                                        required={false}
                                        defaultValue={row.innerBlocks}
                                        disabled={["accordion", "pageContent"]}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "switchTabs" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldList
                                        form={form}
                                        name={`${name}[${n}].tabs`}
                                        required={true}
                                        label={"Tabs"}
                                        addButtonLabel={"Add Tab"}
                                        min={1}
                                        max={4}
                                    >

                                        {(row.tabs || []).map((page, i) => (

                                            <FieldBlocksSwitchTabs
                                                key={i}
                                                form={form}
                                                page={page}
                                                prefix={`${name}[${n}].tabs[${i}]`}
                                            />

                                        ))}

                                    </FieldList>

                                    {/* <FieldRepeater
                                        form={form}
                                        name={`${name}[${n}].tabs`}
                                        addButtonLabel={"Add Tab"}
                                    >

                                        {(row.tabs || []).map((rowTab, rowN) => (

                                            <React.Fragment key={form.key + "-" + n}>

                                                <Row gutter={8} edge={true}>

                                                    <Col col={{ xs: 12, md: 12 }}>

                                                        <FieldText
                                                            form={form}
                                                            label={"Title"}
                                                            name={`${name}[${n}].tabs[${rowN}].title`}
                                                            required={false}
                                                            defaultValue={rowTab.title}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24 }}>

                                                        <FieldBlocks
                                                            form={form}
                                                            addButtonLabel={"Inner Content Block"}
                                                            name={`${name}[${n}].tabs[${rowN}].innerBlocks`}
                                                            required={false}
                                                            defaultValue={rowTab.innerBlocks}
                                                            disabled={["switchTabs", "pageContent", "accordion"]}
                                                        />

                                                    </Col>

                                                </Row>

                                            </React.Fragment>

                                        ))}

                                    </FieldRepeater> */}

                                </Col>

                            </Row>
                        }

                        {row.type === "loadingGuide" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Loading Tables"}
                                        name={`${name}[${n}].loadingTablesIds`}
                                        defaultValue={row.loadingTablesIds}
                                        endpoint={"LoadingTables"}
                                        isMulti={true}
                                        required={true}
                                        preload={true}
                                    />

                                </Col>

                            </Row>
                        }

                    </React.Fragment>

                ))}

            </FieldRepeater>

        </>
    )

}

export default FieldBlocks;