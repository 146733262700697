import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import RenderContentBlocks from '../../../components/ContentBlocks/RenderContentBlocks';

const PagesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Pages Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: 'ModelLink'
                            },
                            {
                                label: "Description",
                                key: "description",
                            },
                            {
                                label: "Section",
                                key: "section",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Parent",
                                key: "parent",
                                format: 'ModelLink'
                            },
                            {
                                label: "Thumbnail",
                                key: "thumbnail.fileId",
                                format: "image",
                                filters: {
                                    width: 60,
                                    height: 60
                                },
                                link: true
                            },
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                    />

                    {data.hasContent &&
                        <>
                            <Seperator
                                heading={"Content"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <RenderContentBlocks
                                blocks={data.contentBlocks}
                            />
                        </>
                    }

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default PagesSingleView;
