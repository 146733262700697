import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';

const LoadingTablesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Loading Table Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Exogen"}
                            name='exogen'
                            required={true}
                            defaultValue={form.defaults.exogen}
                            options={[
                                { value: "Sleeveless Top - Front", label: "Sleeveless Top - Front" },
                                { value: "Sleeveless Top - Back", label: "Sleeveless Top - Back" },
                                { value: "Sleeveless Top - Front & Back", label: "Sleeveless Top - Front & Back" },
                                { value: "Fullarm Sleeve", label: "Fullarm Sleeve" },
                                { value: "Upperarm Sleeve", label: "Upperarm Sleeve" },
                                { value: "Forearm Sleeve", label: "Forearm Sleeve" },
                                { value: "Shorts", label: "Shorts" },
                                { value: "Calf Sleeve", label: "Calf Sleeve" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Table Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "precise", label: "Precise" },
                                { value: "general", label: "General" },
                            ]}
                        />

                    </Col>

                    {form.values.type === "precise" &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"Placement"}
                                name='placement'
                                required={true}
                                defaultValue={form.defaults.placement}
                            />

                        </Col>
                    }

                    {form.values.type === "general" &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldUpload
                                form={form}
                                folder={'admin-media'}
                                label={"Thumbnail"}
                                name={`thumbnail`}
                                required={true}
                                defaultValue={form.defaults.thumbnail}
                                limit={1}
                            />

                        </Col>
                    }

                </Row>

                <Seperator
                    heading={"Table"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldRepeater
                    form={form}
                    name={'table'}
                    addButtonLabel={"Add Table Information"}
                >

                    {form.values.table.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Body Weight Min"}
                                        name={`table[${n}].bodyWeightMin`}
                                        required={true}
                                        defaultValue={row.bodyWeightMin}
                                        suffix={" KG"}
                                    />

                                    <FieldNumber
                                        form={form}
                                        label={"Body Weight Max"}
                                        name={`table[${n}].bodyWeightMax`}
                                        required={true}
                                        defaultValue={row.bodyWeightMax}
                                        suffix={" KG"}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Load Weight"}
                                        name={`table[${n}].loadWeight`}
                                        required={true}
                                        defaultValue={row.loadWeight}
                                        suffix={" G"}
                                    />

                                </Col>

                                {form.values.type === "precise" &&

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldUpload
                                            form={form}
                                            folder={'admin-media'}
                                            label={"Thumbnail"}
                                            name={`table[${n}].thumbnail`}
                                            required={false}
                                            defaultValue={row.thumbnail}
                                            limit={4}
                                        />

                                    </Col>
                                }

                            </Row>

                        </React.Fragment>

                    ))}

                </FieldRepeater>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default LoadingTablesCreateEditForm;
