import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldText } from '@dex/bubl-dash';
import { FieldRadio } from '@dex/bubl-dash';
import { FieldUpload } from '@dex/bubl-dash';
import { FieldTextArea } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';


const VideosCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Videos Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 26 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Short Description"}
                                    name='description'
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Video Topics"}
                                    name='topicId'
                                    isMulti={false}
                                    required={true}
                                    preload={true}
                                    endpoint={'VideoTopics'}
                                    defaultValue={form.defaults.topicId}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport / Activity"}
                                    name='activityId'
                                    isMulti={false}
                                    required={false}
                                    preload={true}
                                    endpoint={'SportsActivities'}
                                    defaultValue={form.defaults.activityId}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Video Source"}
                                    name='source'
                                    required={true}
                                    defaultValue={form.defaults.source}
                                    options={[
                                        { value: "youtube", label: "Youtube" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Video Source ID"}
                                    name='sourceId'
                                    required={true}
                                    defaultValue={form.defaults.sourceId}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "preview", label: "Preview" },
                                        { value: "live", label: "Live" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VideosCreateEditForm;
