import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LoadingTablesApi from './LoadingTablesApi';

let settings;

export default settings = {
    key: 'training/loading-tables',
    zone: 'training',
    path: '/training/loading-tables',
    title: "Loading Tables",
    endpoint: LoadingTablesApi.endpoint,
    modelName: 'LoadingTables',
    idKey: 'id',
    primaryKey: 'title',
    actions: ["trash", "clone"],
    indexCaps: ["viewLoadingTables"],
    viewCaps: ["viewLoadingTables"],
    createCaps: ["createLoadingTables"],
    updateCaps: ["updateLoadingTables"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Precise",
                path: settings.path + "/type/precise",
                caps: settings.indexCaps
            },
            {
                label: "General",
                path: settings.path + "/type/general",
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LoadingTablesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        if (params && params.type) filters.where.type = { inq: [params.type] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "this",
                type: "text",
            },
            {
                label: "Exogen",
                key: "exogen",
                type: "choice",
                options: [
                    { value: "Sleeveless Top", label: "Sleeveless Top" },
                    { value: "Fullarm Sleeve", label: "Fullarm Sleeve" },
                    { value: "Upperarm Sleeve", label: "Upperarm Sleeve" },
                    { value: "Forearm Sleeve", label: "Forearm Sleeve" },
                    { value: "Shorts", label: "Shorts" },
                    { value: "Calf Sleeve", label: "Calf Sleeve" },
                ]
            },
            {
                label: "Placement",
                key: "placement",
                type: "text",
            },
            {
                label: "Type",
                key: "type",
                type: "choice",
                options: [
                    { value: "precise", label: "Precise" },
                    { value: "general", label: "General" }
                ]
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Exogen",
                key: "exogen",
            },
            {
                label: "Type",
                key: "type",
                format: "titleCase"
            },
            {
                label: "Placement",
                key: "placement",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: LoadingTablesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: LoadingTablesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['product'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};