import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

const UsersInfoPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"User Info"}
        >

            <MetaTable
                data={data}
                columnA={[

                    {
                        label: "Default Country",
                        key: "defaultCountry",
                    },
                    {
                        label: "Preferred Currency",
                        key: "preferredCurrency",
                    },
                ]}
                columnB={[
                    {
                        label: "Preferred Unit Length",
                        key: "preferredUnit",
                    },
                    {
                        label: "Preferred Unit Weight",
                        key: "preferredUnitWeight",
                    },
                ]}
                columnC={[
                    {
                        label: "Body Weight (KG)",
                        key: "weightKg",
                        format: "number",
                        suffix: " KG"
                    },
                    {
                        label: "Sports/Activities",
                        key: "interestedInNames"
                    }
                ]}
            />

        </Panel>

    ), [data]);

}

export default UsersInfoPanel;
