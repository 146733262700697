import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRelationship } from '@dex/bubl-dash';
import { FieldRadio } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { FieldSelect } from '@dex/bubl-dash';


const AvatarsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Avatar Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 22 }}>
                                <FieldRelationship
                                    form={form}
                                    label={'User'}
                                    name={'userId'}
                                    isMulti={false}
                                    required={false}
                                    preload={true}
                                    endpoint={'Users'}
                                    defaultValue={form.defaults.userId}
                                />

                            </Col>
                            <Col col={{ xs: 24, md: 22 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Sport"}
                                    name={'sport'}
                                    required={true}
                                    defaultValue={form.defaults.sport}
                                    options={[
                                        { value: "runner", label: "Runner" },
                                        { value: "golfer", label: "Golfer" },
                                        { value: "swimmer", label: "Swimmer" },
                                        { value: "sprinter", label: "Sprinter" },
                                        { value: "wrestler", label: "Wrestler" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Gender"}
                                    name={'gender'}
                                    defaultValue={form.defaults.gender}
                                    options={[
                                        { value: "male", label: "Male" },
                                        { value: "female", label: "Female" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AvatarsCreateEditForm;
