import AppActivityIndex from './AppActivityIndex';
import AppActivityCreate from './AppActivityCreate';
import AppActivitySettings from './AppActivitySettings';
import AppActivitySingle from './AppActivitySingle';

export default function (app) {

    const settings = AppActivitySettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // }

        ],
    });

    AppActivityIndex(app, settings);

    AppActivityCreate(app, settings);

    AppActivitySingle(app, settings);

}