import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import FieldBlocks from '../../../components/ContentBlocks/FieldBlocks';

const TrainingProgramsFormGuidePage: React.FC<any> = (props: any) => {

    const { form, page, prefix } = props;

    return useMemo(() => (

        <FieldPopup
            buttonLabel={"Edit Page " + (page.title ? ` - ${page.title}` : "")}
            heading={'Edit Page'}
            form={form}
            name={prefix}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldText
                        form={form}
                        label={"Page Title"}
                        name={`${prefix}.title`}
                        required={true}
                        defaultValue={page.title}
                    />

                </Col>
                <Col col={{ xs: 24, md: 24 }}>

                    <FieldBlocks
                        form={form}
                        name={`${prefix}.contentBlocks`}
                        defaultValue={page.contentBlocks}
                    />

                </Col>

            </Row>

        </FieldPopup>

    ), [props]);

}

export default TrainingProgramsFormGuidePage;
