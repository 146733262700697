import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';


const TrainingDataCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Training Data Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            name={"data"}
                            label={"Data"}
                            defaultValue={form.defaults.data}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            name={"slug"}
                            label={"Slug"}
                            defaultValue={form.defaults.slug}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={`Data Point`}
                            name={`dataPointId`}
                            isMulti={false}
                            required={false}
                            preload={true}
                            endpoint={'DataPoints'}
                            defaultValue={form.defaults.dataPointId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={`User`}
                            name={`userId`}
                            isMulti={false}
                            required={false}
                            preload={true}
                            endpoint={'Users'}
                            defaultValue={form.defaults.userId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={`Session`}
                            name={`sessionId`}
                            isMulti={false}
                            required={false}
                            preload={true}
                            endpoint={'TrainingSessions'}
                            defaultValue={form.defaults.sessionId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={`Program`}
                            name={`programId`}
                            isMulti={false}
                            required={false}
                            preload={true}
                            endpoint={'TrainingPrograms'}
                            defaultValue={form.defaults.programId}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TrainingDataCreateEditForm;
