import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

const TrainingProgramsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Training Programs Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Description",
                                key: "description",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Activity",
                                key: "activity",
                                format: "modelLink"
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Format",
                                key: "format",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Video",
                                key: "video.fileId",
                                format: "image",
                                filters: {
                                    width: 60,
                                    height: 60
                                },
                                link: true
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default TrainingProgramsSingleView;
