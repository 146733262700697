import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import UsersMods from './views/Users/UsersMods';

import Pages from './views/Pages/Pages';
import Products from './views/Products/Products';
import SportsActivities from "./views/SportsActivities/SportsActivities";
import TrainingPrograms from './views/TrainingPrograms/TrainingPrograms';
import TrainingData from './views/TrainingData/TrainingData';
import LoadingTables from './views/LoadingTables/LoadingTables';
import TrainingProgress from './views/TrainingProgress/TrainingProgress';

//Icons
import TraningWhite from './assets/Training-White.svg';
import TraningBlack from './assets/Training-Black.svg';
import VideoBlack from './assets/Video-Black.svg';
import VideoWhite from './assets/Video-White.svg';

import './styles/App.scss';
import Feedback from './views/Feedback/Feedback';
import Avatars from './views/Avatars/Avatars';
import Videos from './views/Videos/Videos';
import VideoTopics from './views/VideoTopics/VideoTopics';
import AppActivity from './views/AppActivity/AppActivity';


const app = new BublDash({
    name: "Lila+ Dash",
    //icon: "assets/logo.png",
    accessRoles: ['admin', 'developer', 'super'], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'user',
            label: 'User'
        },
        {
            key: 'coach',
            label: 'Coach'
        },
        {
            key: 'ambassador',
            label: 'Ambassador'
        },
        {
            key: 'influencer',
            label: 'Influencer'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        }
    ],
    env: window['bublENV'] || process.env.REACT_APP_ENV || "development", //set env by modifying the output sent by express server
});

app.addRoute({
    name: "home",
    path: "/",
    component: () => <></>,
    componentProps: {},
    caps: [],
});

app.addZone({
    key: "training",
    label: "Training",
    priority: 11,
    icon: TraningBlack,
    iconActive: TraningWhite,
});

app.addZone({
    key: "videos",
    label: "Videos",
    priority: 12,
    icon: VideoBlack,
    iconActive: VideoWhite,
});

//Add New Models Here
const extensions: any = [
    Defaults,
    Pages,
    // TrainingSessions,
    TrainingPrograms,
    TrainingProgress,
    SportsActivities,
    Products,
    LoadingTables,
    TrainingData,
    Users,
    UsersMods,
    Feedback,
    Avatars,
    Media,
    Videos,
    VideoTopics,
    AppActivity
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;