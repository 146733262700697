/* eslint-disable react-app/react/jsx-no-undef */
import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldBlocks from '../../../components/ContentBlocks/FieldBlocks';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const TrainingProgramsFormDataPoint: React.FC<any> = (props: any) => {

    const { form, point, prefix, label } = props;

    let title = point.title && point.enabled ? point.title : "Set Data Point";

    return useMemo(() => (

        <FieldPopup
            buttonLabel={title}
            label={label}
            heading={`Data Point`}
            form={form}
            name={prefix}
            width={800}
        >

            <FieldSwitch
                form={form}
                label={"Enable Data Point"}
                name={`${prefix}.enabled`}
                defaultValue={point.enabled}
            />

            {point.enabled &&
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name={`${prefix}.title`}
                            required={true}
                            defaultValue={point.title}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            label={"Description"}
                            name={`${prefix}.description`}
                            required={true}
                            defaultValue={point.description}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Data Key"}
                            name={`${prefix}.key`}
                            required={true}
                            defaultValue={point.key}
                            placeholder={"Fixed referrence key. No Spaces, No Dashes"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            label={"Data Type"}
                            name={`${prefix}.type`}
                            required={true}
                            defaultValue={point.type}
                            options={[
                                { value: "time", label: "Time" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Data Input Label"}
                            name={`${prefix}.label`}
                            required={true}
                            defaultValue={point.label}
                            placeholder={"Input Field Label"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Data Display Label"}
                            name={`${prefix}.displayLabel`}
                            required={true}
                            defaultValue={point.displayLabel}
                            placeholder={"Display Label"}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Seperator
                            heading={"Content"}
                            bottom={"small"}
                        />

                        <FieldBlocks
                            form={form}
                            name={`${prefix}.contentBlocks`}
                            defaultValue={point.contentBlocks}
                            disabled={["accordion", "pageContent", "loadingGuide"]}
                        />

                    </Col>

                </Row>
            }

        </FieldPopup>

    ), [props]);

}

export default TrainingProgramsFormDataPoint;
